
import {Component, Ref, Vue, Watch} from "vue-property-decorator";
import api from "@/api";
import Tinymce from "@/components/Tinymce/index.vue";
import {
  ProjectDto,
  DataDictionaryDto,
  PartnerCapitalApplySettlementCreateOrUpdateDto,
  PartnerCapitalApplySettlementDetailCreateOrUpdateDto,
  PartnerCapitalApplyDto,
  PartnerCapitalApplyDtoPagedResultDto,
  MatterAuditFormCreateOrUpdateDto,
  PrivateCarPublicSubsidyFormCreateOrUpdateDto,
  TravelApprovalFormCreateOrUpdateDto, AttachmentHostType,
} from "@/api/appService";
import {ElForm} from "element-ui/types/form";
import SimpleUploadImage from "@/components/SimpleUploadImage/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import ProjectSelect from "@/components/ProjectSelect/index.vue";
import MultipleUploadFile from "@/components/MultipleUploadFile/index.vue";
import BudgetItems from "@/components/BudgetItems/index.vue";
import {v4 as uuidv4} from "uuid";
import {UserModule} from "@/store/modules/user";
import BudgetItem from "@/views/project/partnerCapitalApplySettlement/components/BudgetItem.vue";
import {computed} from "vue";
import {watch} from "fs";

@Component({
  components: {BudgetItem, Tinymce, SimpleUploadImage, AbSelect, ProjectSelect, MultipleUploadFile, BudgetItems}
})
export default class CreateTravelApproval extends Vue {
  @Ref() readonly dataForm!: ElForm;
  dataId!: number;

  form: TravelApprovalFormCreateOrUpdateDto = {
    id: undefined,
    projectName: undefined,
    travelTime: undefined,
    destination: undefined,
    travelers: undefined,
    travelMode: undefined,
    subject: undefined,
    schedule: undefined,
    auditFlowId: undefined,
  };


  created() {
    this.dataId = Number(this.$route.params.id);
    if (this.dataId) {
      api.travelApproval
        .get({id: this.dataId})
        .then((res: any) => {
          this.form = {...res};
        })
    }
  }

  async save() {
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (this.form!.id) {
          await api.travelApproval.update({
            body: this.form
          }).then(() => {
            this.$router.back();
            this.$message.success("更新成功");
          });
        } else {
          await api.travelApproval.create({
            body: this.form
          }).then(() => {
            this.$router.back();
            this.$message.success("新建成功");
          });
        }
      }
    });
  }

  cancel() {
    this.$router.back();
  }

  get hostType() {
    return AttachmentHostType.Temp;
  }

  roleRule = {
    projectName: [
      {
        required: true,
        message: "请填写项目名称",
        trigger: "blur"
      }
    ],
    travelTime: [
      {
        required: true,
        message: "请填写出行时间",
        trigger: "blur"
      }
    ],
    destination: [
      {
        required: true,
        message: "请填写目的地",
        trigger: "blur"
      }
    ],
    travelers: [
      {
        required: true,
        message: "请填写出行人员",
        trigger: "blur"
      }
    ],
    travelMode: [
      {
        required: true,
        message: "请填写出行方式",
        trigger: "blur"
      }
    ],

    subject: [
      {
        required: true,
        message: "请填写本期主题",
        trigger: "blur"
      }
    ],
    schedule: [
      {
        required: true,
        message: "请填写行程安排及主要内容",
        trigger: "blur"
      }
    ]
  };
}


import {Component, Ref, Vue, Watch} from "vue-property-decorator";
import api from "@/api";
import Tinymce from "@/components/Tinymce/index.vue";
import {
  ProjectDto,
  DataDictionaryDto,
  PartnerCapitalApplySettlementCreateOrUpdateDto,
  PartnerCapitalApplySettlementDetailCreateOrUpdateDto,
  PartnerCapitalApplyDto,
  PartnerCapitalApplyDtoPagedResultDto,
  MatterAuditFormCreateOrUpdateDto,
  PrivateCarPublicSubsidyFormCreateOrUpdateDto,
  ItemBorrowingFormCreateOrUpdateDto, AttachmentHostType,
} from "@/api/appService";
import {ElForm} from "element-ui/types/form";
import SimpleUploadImage from "@/components/SimpleUploadImage/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import ProjectSelect from "@/components/ProjectSelect/index.vue";
import MultipleUploadFile from "@/components/MultipleUploadFile/index.vue";
import BudgetItems from "@/components/BudgetItems/index.vue";
import {v4 as uuidv4} from "uuid";
import {UserModule} from "@/store/modules/user";
import ItemEdit from "@/views/workbench/itemBorrowing/itemEdit.vue";
import {computed} from "vue";
import {watch} from "fs";

@Component({
  components: {ItemEdit, Tinymce, SimpleUploadImage, AbSelect, ProjectSelect, MultipleUploadFile, BudgetItems}
})
export default class CreateItemBorrowing extends Vue {
  @Ref() readonly dataForm!: ElForm;
  dataId!: number;

  isReturnEdit?: boolean = false;

  form: ItemBorrowingFormCreateOrUpdateDto = {
    id: undefined,
    description: undefined,
    backTime: undefined,
    actualBackTime: undefined,
    itemsJson: undefined,
    auditFlowId: undefined,
  };

  itemList: any[] = [];

  handleCreate() {
    let item = {
      name: '',
      borrowAmount: undefined,
      backAmount: undefined,
      remark: ''
    } as any;

    (this.$refs.editForm as any).dataIndex = undefined;
    (this.$refs.editForm as any).form = item;
    (this.$refs.editForm as any).show = true;
  }

  handleEdit(item: any, index: number) {
    console.log('123123:', item);
    (this.$refs.editForm as any).dataIndex = index;
    (this.$refs.editForm as any).form = item;
    (this.$refs.editForm as any).show = true;
  }

  handleDelete(index: number) {
    this.itemList!.splice(index, 1);
  }

  handelOnSaved(res: any) {
    if (res.index != undefined) {
      let target = this.itemList[res.index];
      target = res.data;
    } else {
      this.itemList = [...this.itemList!, res.data];
    }
    console.log(11, res)
  }


  created() {
    this.dataId = Number(this.$route.params.id);
    if (this.$route.query.isReturnEdit) {
      this.isReturnEdit = true;
    }


    if (this.dataId) {
      api.itemBorrowing
        .get({id: this.dataId})
        .then((res: any) => {
          this.form = {...res};
          this.itemList = JSON.parse(this.form.itemsJson ?? '');
        })
    }
  }

  async save() {
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (this.itemList && this.itemList.length > 0) {
          this.form.itemsJson = JSON.stringify(this.itemList);
        } else {
          this.$message.error("请添加至少一个借用物品");
          return;
        }

        if (this.isReturnEdit && this.itemList.find(s => s.backAmount == undefined)) {
          this.$message.error("请填写归还数量");
          return;
        }


        if (this.form!.id) {
          await api.itemBorrowing.update({
            body: this.form
          }).then(() => {
            this.$router.back();
            this.$message.success("更新成功");
          });
        } else {
          await api.itemBorrowing.create({
            body: this.form
          }).then(() => {
            this.$router.back();
            this.$message.success("新建成功");
          });
        }
      }
    });
  }

  cancel() {
    this.$router.back();
  }

  roleRule = {
    description: [
      {
        required: true,
        message: "请填写借用事项说明",
        trigger: "blur"
      }
    ],
  };
}


import {
  Component,
  Vue,
  Inject,
  Prop,
  Watch,
  Ref
} from "vue-property-decorator";

import api from "@/api";

import {ElForm} from "element-ui/types/form";
import validate from "@/utils/validate";
import {getCapitalPropertyList, getCurrencyType, getIncomeCategory, getPayTypes} from "@/utils/enumToSelectItems";
import SimpleUploadFile from "@/components/SimpleUploadFile/index.vue";

@Component({
  name: "EditGoodsDetail",
  components: {SimpleUploadFile},
  filters: {}
})
export default class EditGoodsDetail extends Vue {
  @Ref() readonly dataForm!: ElForm;

  defaultData: any = {
    date: undefined,
    amount: undefined,
    summary: undefined,
  };

  @Prop({required: true})
  isReturnEdit!: boolean;

  show = false;
  form: any = {...this.defaultData};
  dataIndex?:number=undefined;

  get title() {
    return '出库物资信息';
  }


  @Watch("show")
  async onShowChange(value: boolean) {
    if (value) {
      const loading = this.$loading({
        target: ".el-dialog"
      });

      if (this.dataIndex!=undefined) {
        setTimeout(() => {
          loading.close();
        }, 200);
      } else {
        this.form = {...this.defaultData};
        setTimeout(() => {
          loading.close();
        }, 200);
      }


    } else {
      this.form = {...this.defaultData};
    }
    this.$nextTick(() => {
      this.dataForm.clearValidate();
    });
  }

  async save() {
    console.log(this.form);
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        this.show = false;
        this.$emit("onSave",{data:this.form,index:this.dataIndex});
      }
    });
  }

  cancel() {
    this.show = false;
  }

  linkRule = {
    date: [
      {
        required: true,
        message: "请选择出库时间",
        trigger: "blur"
      }
    ],
    amount: [
      {
        required: true,
        message: "请填写数量",
        trigger: "blur"
      }
      /*{
                    type: "number",
                    message: "账号必须为数字",
                    trigger: "blur"
                }*/
    ],
    summary: [
      {
        required: true,
        message: "请填写项目及事由",
        trigger: "blur"
      }
    ]
  };

  // detailRule = {
  //   money: [
  //     {
  //       required: true,
  //       trigger: "change",
  //       validator: validate.validMoney
  //     }
  //   ]
  // };
}


import {Component, Ref, Vue, Watch} from "vue-property-decorator";
import api from "@/api";
import Tinymce from "@/components/Tinymce/index.vue";
import {
  ProjectDto,
  DataDictionaryDto,
  PartnerCapitalApplySettlementCreateOrUpdateDto,
  PartnerCapitalApplySettlementDetailCreateOrUpdateDto,
  PartnerCapitalApplyDto,
  PartnerCapitalApplyDtoPagedResultDto,
  MatterAuditFormCreateOrUpdateDto,
  PrivateCarPublicSubsidyFormCreateOrUpdateDto,
  VehicleUsageApplicationFormCreateOrUpdateDto, AttachmentHostType,
} from "@/api/appService";
import {ElForm} from "element-ui/types/form";
import SimpleUploadImage from "@/components/SimpleUploadImage/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import ProjectSelect from "@/components/ProjectSelect/index.vue";
import MultipleUploadFile from "@/components/MultipleUploadFile/index.vue";
import BudgetItems from "@/components/BudgetItems/index.vue";
import {v4 as uuidv4} from "uuid";
import {UserModule} from "@/store/modules/user";
import BudgetItem from "@/views/project/partnerCapitalApplySettlement/components/BudgetItem.vue";
import {computed} from "vue";
import {watch} from "fs";

@Component({
  components: {BudgetItem, Tinymce, SimpleUploadImage, AbSelect, ProjectSelect, MultipleUploadFile, BudgetItems}
})
export default class CreateVehicleUsageApplication extends Vue {
  @Ref() readonly dataForm!: ElForm;
  dataId!: number;

  form: VehicleUsageApplicationFormCreateOrUpdateDto = {
    id: undefined,
    applyReason: undefined,
    startTime: undefined,
    startPlace: undefined,
    startRemark: undefined,
    startKilometersImage: undefined,
    startKilometers: undefined,

    endTime: undefined,
    endPlace: undefined,
    endRemark: undefined,
    endKilometersImage: undefined,
    endKilometers: undefined,
    situation: undefined,
    savedAttachments: [],
    auditFlowId: undefined,
  };


  created() {
    this.dataId = Number(this.$route.params.id);
    if (this.dataId) {
      api.vehicleUsageApplication
        .get({id: this.dataId})
        .then((res: any) => {
          this.form = {...res};
        })
    }
  }

  async save() {
    this.form.startPlace = "基金会出发";
    this.form.endPlace = "回基金会";
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (this.form!.id) {
          await api.vehicleUsageApplication.update({
            body: this.form
          }).then(() => {
            this.$router.back();
            this.$message.success("更新成功");
          });
        } else {
          await api.vehicleUsageApplication.create({
            body: this.form
          }).then(() => {
            this.$router.back();
            this.$message.success("新建成功");
          });
        }
      }
    });
  }

  cancel() {
    this.$router.back();
  }

  get hostType() {
    return AttachmentHostType.Temp;
  }

  get hostTypeMultiple() {
    return AttachmentHostType.VehicleUsageApplication;
  }

  roleRule = {
    applyReason: [
      {
        required: true,
        message: "请填写申请事由",
        trigger: "blur"
      }
    ],
    startTime: [
      {
        required: true,
        message: "请填写预计出车时间",
        trigger: "blur"
      }
    ],
    startPlace: [
      {
        required: true,
        message: "请填写出发地",
        trigger: "blur"
      }
    ],
    startKilometers: [
      {
        required: true,
        message: "请填写出车前公里数",
        trigger: "blur"
      }
    ],
    startKilometersImage: [
      {
        required: false,
        message: "请上传出车前公里数照片",
        trigger: "blur"
      }
    ],

    endTime: [
      {
        required: true,
        message: "请填写预计还车时间",
        trigger: "blur"
      }
    ],
    endPlace: [
      {
        required: true,
        message: "请填写还车地",
        trigger: "blur"
      }
    ],
  };
}


import api from "@/api"; //ABP API接口
import {Vue, Component, Ref, Watch, Prop} from "vue-property-decorator";

import {
  OrganizationUnitCreateOrUpdateDto,
  PartnerCapitalApplyDto,
  AuditFlowScope,
  PrivateCarPublicSubsidyFormDto,
  AttachmentHostType,
  PrivateCarPublicSubsidyFormProcessInputDto
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";
import OrganizationSelect from "@/components/OrganizationSelect/index.vue";
import SimpleUploadImage from "@/components/SimpleUploadImage/index.vue";
import {ElForm} from "element-ui/types/form";

@Component({
  name: "RoleList",
  components: {
    SimpleUploadImage,
    PagedTableView,
    AbSelect,
    AgileAuditTag,
    OrganizationSelect
  }
})

export default class PartnerCapitalApplyList extends Vue {
  @Ref() readonly dataForm!: ElForm;
  @Ref() readonly pagedTableView!: any;

  queryForm = {
    startDate: "",
    endDate: ""
  };

  form: PrivateCarPublicSubsidyFormProcessInputDto = {
    endTime: undefined,
    endDeparture: undefined,
    endDestination: undefined,
    endKilometers: undefined,
    totalKilometers: undefined,
    fee: undefined,
    id: undefined,
  };

  @Prop({required: false})
  createdByMe?: boolean;

  @Prop({required: false})
  approved?: boolean;

  get hostType() {
    return AttachmentHostType.Temp;
  }

  // 获取表数据
  fetchData(params: any) {
    return api.privateCarPublicSubsidyForm.getAll({
      ...params,
      createdByMe: this.createdByMe,
      isApproved: this.approved
    });
  }

  endKilometersChange() {
    this.form.fee = (this.form.endKilometers ?? 0) * 1.5;
  }

  //获取审核状态
  getAuditStatus(index: number, row: any) {
    if ((row.audit != null && row.audit >= 0) && row.auditStatus === row.audit) {//已审核
      return true;
    } else if (row.audit === -1) {//被拒绝
      return false;
    } else if (row.audit == null) {//未开始
      return false;
    } else {//审核中
      return true;
    }
  }

  // 新建
  handleCreate() {
    this.$router.push({
      name: "privateCarPublicSubsidy-create",
    });
  }

  // 编辑
  handleEdit(index: number, row: any) {
    this.$router.push({
      name: "privateCarPublicSubsidy-edit",
      params: {
        id: row.id
      },
    });
  }

  isShowProcess = false;
  privateCarPublicSubsidyId: number = 0;

  isAuditPass(index: number, row: any) {
    return (row.audit != null && row.audit >= 0) && row.auditStatus === row.audit;
  }

  async saveProcess() {
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        this.form.id = this.privateCarPublicSubsidyId;

        await api.privateCarPublicSubsidyForm.process({
          body: this.form
        }).then(() => {
          this.isShowProcess = false;
          this.$message.success("处理成功");
        });
      }
    });
  }

  handleMenu(e: any) {
    switch (e.type) {
      case "detail": {
        this.jumpDetail(e.index, e.item);
        return;
      }
      case "edit": {
        this.handleEdit(e.index, e.item);
        return;
      }
      case "delete": {
        this.handleDelete(e.index, e.item);
        return;
      }
      case "startAudit": {
        this.handleStartAudit(e.index, e.item);
        return;
      }
      case "auditFlow": {
        this.handleAuditFlow(e.index, e.item);
        break;
      }
      case "process": {
        this.handleProcess(e.index, e.item);
        break;
      }
    }
  }

  handleProcess(index: number, row: PrivateCarPublicSubsidyFormDto) {
    this.isShowProcess = true;
    this.privateCarPublicSubsidyId = row.id!;
    this.form.endTime = undefined;
    this.form.endDeparture = undefined;
    this.form.endDestination = undefined;
    this.form.endKilometers = undefined;
    this.form.totalKilometers = undefined;
    this.form.fee = undefined;
  }

  // 查看详情
  jumpDetail(index: number, row: PartnerCapitalApplyDto) {
    this.$router.push({
      name: "privateCarPublicSubsidy-detail",
      params: {id: row.id!.toString()}
    });
  }

  // 提交审核
  async handleStartAudit(
    item: number,
    node: OrganizationUnitCreateOrUpdateDto
  ) {
    await api.privateCarPublicSubsidyForm.startAudit({
      body: {
        id: node.id
      }
    }).then(res => {
      this.$message({
        type: "success",
        message: "操作成功!"
      });
    })
  }

  // 删除
  async handleDelete(index: number, row: OrganizationUnitCreateOrUpdateDto) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.privateCarPublicSubsidyForm.delete({id: row.id}).then(res => {
        this.$message({
          type: "success",
          message: "删除成功!"
        });
      });
    });
  }

  handleAuditFlow(index: number, row: any) {
    this.$router.push({
      name: "auditFlowDetail",
      params: {
        hostType: AuditFlowScope.PrivateCarPublicSubsidy,
        hostId: row.id,
        auditFlowId: row.auditFlowId
      }
    });
  }

  roleRule = {
    endTime: [
      {
        required: true,
        message: "请填写出发时间",
        trigger: "blur"
      }
    ],
    endKilometers: [
      {
        required: true,
        message: "请填写行公里数",
        trigger: "blur"
      }
    ],
    endDeparture: [
      {
        required: true,
        message: "请填写出发地",
        trigger: "blur"
      }
    ],
    endDestination: [
      {
        required: true,
        message: "请填写目的地",
        trigger: "blur"
      }
    ],
  };
}


import {Vue, Component, Prop} from 'vue-property-decorator';

@Component({})



export default class AuditMenu extends Vue {

  @Prop({required: false})
  auditItem?: string;

  jumpAudit(name:string){
    this.$router.push({
      name: name,
    });
  }
}

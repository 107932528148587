
import api from "@/api"; //ABP API接口
import {Component, Prop, Ref, Vue} from "vue-property-decorator";

import {
  AuditFlowScope,
  ItemBorrowingFormDto,
  ItemBorrowingFormProcessInputDto,
  OrganizationUnitCreateOrUpdateDto,
  PartnerCapitalApplyDto
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";
import OrganizationSelect from "@/components/OrganizationSelect/index.vue";
import {ElForm} from "element-ui/types/form";
import SimpleUploadImage from "@/components/SimpleUploadImage/index.vue";

@Component({
  name: "RoleList",
  components: {
    SimpleUploadImage,
    PagedTableView,
    AbSelect,
    AgileAuditTag,
    OrganizationSelect
  }
})
export default class ItemBorrowingList extends Vue {
  @Ref() readonly dataForm!: ElForm;
  @Ref() readonly pagedTableView!: any;

  queryForm = {
    description: "",
    startDate: "",
    endDate: ""
  };

  @Prop({required: false})
  createdByMe?: boolean;

  @Prop({required: false})
  approved?: boolean;

  isShowProcess = false;
  itemBorrowingId: number = 0;

  form: ItemBorrowingFormProcessInputDto = {
    actualBackTime: undefined,
  };

  // 获取表数据
  fetchData(params: any) {
    return api.itemBorrowing.getAll({...params, createdByMe: this.createdByMe, isApproved: this.approved});
  }

  isAuditPass(index: number, row: any) {
    return (row.audit != null && row.audit >= 0) && row.auditStatus === row.audit;
  }

  async saveProcess() {
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        this.form.id = this.itemBorrowingId;

        await api.itemBorrowing.process({
          body: this.form
        }).then(() => {
          this.isShowProcess = false;
          this.$message.success("处理成功");
        });
      }
    });
  }

  //获取审核状态
  getAuditStatus(index: number, row: any) {
    if ((row.audit != null && row.audit >= 0) && row.auditStatus === row.audit) {//已审核
      return true;
    } else if (row.audit === -1) {//被拒绝
      return false;
    } else if (row.audit == null) {//未开始
      return false;
    } else {//审核中
      return true;
    }
  }

  // 新建
  handleCreate() {
    this.$router.push({
      name: "itemBorrowing-create",
    });
  }

  // 编辑
  handleEdit(index: number, row: any) {
    this.$router.push({
      name: "itemBorrowing-edit",
      params: {
        id: row.id
      },
    });
  }

  handleMenu(e: any) {
    switch (e.type) {
      case "detail": {
        this.jumpDetail(e.index, e.item);
        return;
      }
      case "edit": {
        this.handleEdit(e.index, e.item);
        return;
      }
      case "delete": {
        this.handleDelete(e.index, e.item);
        return;
      }
      case "startAudit": {
        this.handleStartAudit(e.index, e.item);
        return;
      }
      case "auditFlow": {
        this.handleAuditFlow(e.index, e.item);
        break;
      }
      case "process": {
        this.handleProcess(e.index, e.item);
        break;
      }
    }
  }

  handleProcess(index: number, row: ItemBorrowingFormDto) {
    this.isShowProcess = true;
    this.itemBorrowingId = row.id!;
    this.form.actualBackTime = "";
  }

  // 查看详情
  jumpDetail(index: number, row: PartnerCapitalApplyDto) {
    this.$router.push({
      name: "itemBorrowing-detail",
      params: {id: row.id!.toString()}
    });
  }

  // 提交审核
  async handleStartAudit(
    item: number,
    node: OrganizationUnitCreateOrUpdateDto
  ) {
    await api.itemBorrowing.startAudit({
      body: {
        id: node.id
      }
    }).then(res => {
      this.$message({
        type: "success",
        message: "操作成功!"
      });
    })
  }

  // 删除
  async handleDelete(index: number, row: OrganizationUnitCreateOrUpdateDto) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.itemBorrowing.delete({id: row.id}).then(res => {
        this.$message({
          type: "success",
          message: "删除成功!"
        });
      });
    });
  }

  handleAuditFlow(index: number, row: any) {
    this.$router.push({
      name: "auditFlowDetail",
      params: {
        hostType: AuditFlowScope.ItemBorrowing,
        hostId: row.id,
        auditFlowId: row.auditFlowId
      }
    });
  }

  roleRule = {
    actualBackTime: [
      {
        required: true,
        message: "请填写归还时间",
        trigger: "blur"
      }
    ]
  };
}

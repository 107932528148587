
import {Component, Ref, Vue, Watch} from "vue-property-decorator";
import api from "@/api";
import Tinymce from "@/components/Tinymce/index.vue";
import {
  ProjectDto,
  DataDictionaryDto,
  PartnerCapitalApplySettlementCreateOrUpdateDto,
  PartnerCapitalApplySettlementDetailCreateOrUpdateDto,
  PartnerCapitalApplyDto,
  PartnerCapitalApplyDtoPagedResultDto,
  MatterAuditFormCreateOrUpdateDto,
  PrivateCarPublicSubsidyFormCreateOrUpdateDto,
  InventoryOutFormCreateOrUpdateDto, AttachmentHostType,
} from "@/api/appService";
import {ElForm} from "element-ui/types/form";
import SimpleUploadImage from "@/components/SimpleUploadImage/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import ProjectSelect from "@/components/ProjectSelect/index.vue";
import MultipleUploadFile from "@/components/MultipleUploadFile/index.vue";
import BudgetItems from "@/components/BudgetItems/index.vue";
import {v4 as uuidv4} from "uuid";
import {UserModule} from "@/store/modules/user";
import ItemEdit from "@/views/workbench/inventoryOut/itemEdit.vue";
import {computed} from "vue";
import {watch} from "fs";

@Component({
  components: {ItemEdit, Tinymce, SimpleUploadImage, AbSelect, ProjectSelect, MultipleUploadFile, BudgetItems}
})
export default class CreateInventoryOut extends Vue {
  @Ref() readonly dataForm!: ElForm;
  dataId!: number;

  isReturnEdit?: boolean = false;

  form: InventoryOutFormCreateOrUpdateDto = {
    id: undefined,
    inventoryInFormId: undefined,
    goods: undefined,
    goodsOther:undefined,
    brand: undefined,
    model: undefined,
    unitPrice: undefined,
    detailJson: undefined,
    auditFlowId: undefined,
  };

  itemList: any[] = [];

  handleCreate() {
    let item = {
      name: '',
      borrowAmount: undefined,
      backAmount: undefined,
      remark: ''
    } as any;

    (this.$refs.editForm as any).dataIndex = undefined;
    (this.$refs.editForm as any).form = item;
    (this.$refs.editForm as any).show = true;
  }

  handleEdit(item: any, index: number) {
    (this.$refs.editForm as any).dataIndex = index;
    (this.$refs.editForm as any).form = item;
    (this.$refs.editForm as any).show = true;
  }

  handleDelete(index: number) {
    this.itemList!.splice(index, 1);
  }

  handelOnSaved(res: any) {
    if (res.index != undefined) {
      let target = this.itemList[res.index];
      target = res.data;
    } else {
      this.itemList = [...this.itemList!, res.data];
    }
    console.log(11, res)
  }


  created() {
    this.dataId = Number(this.$route.params.id);
    if (this.$route.query.isReturnEdit) {
      this.isReturnEdit = true;
    }

    this.getGoodsList();
    if (this.dataId) {
      api.inventoryOut
        .get({id: this.dataId})
        .then((res: any) => {
          this.form = {...res};
          this.itemList = JSON.parse(this.form.detailJson ?? '');
          //
          let data=res.goodsOther.split('|');
          api.inventoryIn.get({id:Number(data[0])}).then(r=>{
            let items=JSON.parse(r.detailJson??'');
            let totalAmount=0;
            items.forEach((d:any)=>{
              if(this.form.unitPrice==d.unitPrice){
                totalAmount+=d.amount;
              }
            })
            this.maxAmount=totalAmount;
          })
        })
    }
  }

  goodsList: any[] = [];

  getGoodsList() {
    api.inventoryIn.getAll({maxResultCount: 65535, isApproved: true}).then(res => {
      //处理
      if (res && res.items && res.items.length > 0) {
        res.items.forEach(s => {
          let sp = JSON.parse(s.detailJson ?? '');
          sp.forEach((d: any) => {
            var text = `${s.goods}-${s.brand}-${s.model}-${d.unitPrice}元`;
            if (!this.goodsList.map(q => q.text).includes(text)) {
              this.goodsList.push({
                text: text,
                value: `${s.id}|${d.unitPrice}`
              })
            }
          })
        })
      }
    })
  }

  maxAmount:number=0;
  handleBaseInfo(res:string){
    this.itemList=[];
    if(res){
      let data=res.split('|');
      api.inventoryIn.get({id:Number(data[0])}).then(r=>{
        console.log(r);
        this.form.inventoryInFormId=Number(data[0]);
        this.form.brand=r.brand;
        this.form.model=r.model;
        this.form.goods=r.goods;
        this.form.unitPrice=Number(data[1]);
        let items=JSON.parse(r.detailJson??'');
        let totalAmount=0;
        items.forEach((d:any)=>{
          if(this.form.unitPrice==d.unitPrice){
            totalAmount+=d.amount;
          }
        })
        this.maxAmount=totalAmount;
      })
    }
  }

  async save() {
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (this.itemList && this.itemList.length > 0) {
          this.form.detailJson = JSON.stringify(this.itemList);
        } else {
          this.$message.error("请添加至少一个出库物资");
          return;
        }

        let totalAmount=0;
        this.itemList.forEach(s=>{
          totalAmount+=s.amount;
        })

        if(totalAmount>this.maxAmount){
          this.$message.error("超出可出库数量");
          return;
        }


        if (this.form!.id) {
          await api.inventoryOut.update({
            body: this.form
          }).then(() => {
            this.$router.back();
            this.$message.success("更新成功");
          });
        } else {
          await api.inventoryOut.create({
            body: this.form
          }).then(() => {
            this.$router.back();
            this.$message.success("新建成功");
          });
        }
      }
    });
  }

  cancel() {
    this.$router.back();
  }

  roleRule = {
    goods: [
      {
        required: true,
        message: "请填写物资",
        trigger: "blur"
      }
    ],
    brand: [
      {
        required: true,
        message: "请填写品牌",
        trigger: "blur"
      }
    ],
    model: [
      {
        required: true,
        message: "请填写型号",
        trigger: "blur"
      }
    ],
    unitPrice: [
      {
        required: true,
        message: "请填写单价",
        trigger: "blur"
      }
    ]
  };
}

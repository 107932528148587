
import {Component, Ref, Vue} from "vue-property-decorator";
import api from "@/api";
import Tinymce from "@/components/Tinymce/index.vue";
import {
  ProjectDto,
  DataDictionaryDto,
  PartnerCapitalApplySettlementCreateOrUpdateDto,
  PartnerCapitalApplySettlementDetailCreateOrUpdateDto,
  PartnerCapitalApplyDto,
  PartnerCapitalApplyDtoPagedResultDto,
  MatterAuditFormCreateOrUpdateDto,
} from "@/api/appService";
import {ElForm} from "element-ui/types/form";
import SimpleUploadImage from "@/components/SimpleUploadImage/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import ProjectSelect from "@/components/ProjectSelect/index.vue";
import MultipleUploadFile from "@/components/MultipleUploadFile/index.vue";
import BudgetItems from "@/components/BudgetItems/index.vue";
import {v4 as uuidv4} from "uuid";
import {UserModule} from "@/store/modules/user";
import BudgetItem from "@/views/project/partnerCapitalApplySettlement/components/BudgetItem.vue";
import {computed} from "vue";

@Component({
  components: {BudgetItem, Tinymce, SimpleUploadImage, AbSelect, ProjectSelect, MultipleUploadFile, BudgetItems}
})
export default class CreatePartnerCapitalApplySettlement extends Vue {
  @Ref() readonly dataForm!: ElForm;
  dataId!: number;

  // 资金预算明细
  partnerCapitalApplyDetails: PartnerCapitalApplySettlementDetailCreateOrUpdateDto[] = [
    {
      purpose: undefined,
      purposeArray: [],
      applyPrice: undefined,
      settlementPrice: undefined,
      remark: undefined,
      otherFund: undefined,
      otherFundSource: undefined,
      invoiceFile: undefined,
    }
  ];

  form: MatterAuditFormCreateOrUpdateDto = {
    id: undefined,
    matter: undefined,
    department: undefined,
    description: undefined,
    auditFlowId: undefined,
  };

  implementStatusItems: any = [];

  created() {
    this.dataId = Number(this.$route.params.id);
    if (this.dataId) {
      api.matterAuditForm
        .get({id: this.dataId})
        .then((res: any) => {
          this.form = {...res};
        })
    }
  }

  async save() {
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (this.form!.id) {
          await api.matterAuditForm.update({
            body: this.form
          }).then(() => {
            this.$router.back();
            this.$message.success("更新成功");
          });
        } else {
          await api.matterAuditForm.create({
            body: this.form
          }).then(() => {
            this.$router.back();
            this.$message.success("新建成功");
          });
        }
      }
    });
  }

  cancel() {
    this.$router.back();
  }

  roleRule = {
    department: [
      {
        required: true,
        message: "请填写报批部门",
        trigger: "blur"
      }
    ],
    matter: [
      {
        required: true,
        message: "请填写审批事项",
        trigger: "blur"
      }
    ],
    description: [
      {
        required: true,
        message: "请填写事项简述",
        trigger: "blur"
      }
    ]
  };
}


import {Component, Ref, Vue, Watch} from "vue-property-decorator";
import api from "@/api";
import Tinymce from "@/components/Tinymce/index.vue";
import {
  ProjectDto,
  DataDictionaryDto,
  PartnerCapitalApplySettlementCreateOrUpdateDto,
  PartnerCapitalApplySettlementDetailCreateOrUpdateDto,
  PartnerCapitalApplyDto,
  PartnerCapitalApplyDtoPagedResultDto,
  MatterAuditFormCreateOrUpdateDto, PrivateCarPublicSubsidyFormCreateOrUpdateDto,
} from "@/api/appService";
import {ElForm} from "element-ui/types/form";
import SimpleUploadImage from "@/components/SimpleUploadImage/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import ProjectSelect from "@/components/ProjectSelect/index.vue";
import MultipleUploadFile from "@/components/MultipleUploadFile/index.vue";
import BudgetItems from "@/components/BudgetItems/index.vue";
import {v4 as uuidv4} from "uuid";
import {UserModule} from "@/store/modules/user";
import BudgetItem from "@/views/project/partnerCapitalApplySettlement/components/BudgetItem.vue";
import {computed} from "vue";
import {watch} from "fs";

@Component({
  components: {BudgetItem, Tinymce, SimpleUploadImage, AbSelect, ProjectSelect, MultipleUploadFile, BudgetItems}
})
export default class CreatePartnerCapitalApplySettlement extends Vue {
  @Ref() readonly dataForm!: ElForm;
  dataId!: number;

  form: PrivateCarPublicSubsidyFormCreateOrUpdateDto = {
    id: undefined,
    applyReason: undefined,
    startTime: undefined,
    startDeparture: undefined,
    startDestination: undefined,
    startKilometers: undefined,
    endTime: undefined,
    endDeparture: undefined,
    endDestination: undefined,
    endKilometers: undefined,
    totalKilometers: undefined,
    fee: undefined,
    auditFlowId: undefined,
  };


  created() {
    this.dataId = Number(this.$route.params.id);
    if (this.dataId) {
      api.privateCarPublicSubsidyForm
        .get({id: this.dataId})
        .then((res: any) => {
          this.form = {...res};
        })
    }
  }

  async save() {
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (this.form!.id) {
          await api.privateCarPublicSubsidyForm.update({
            body: this.form
          }).then(() => {
            this.$router.back();
            this.$message.success("更新成功");
          });
        } else {
          await api.privateCarPublicSubsidyForm.create({
            body: this.form
          }).then(() => {
            this.$router.back();
            this.$message.success("新建成功");
          });
        }
      }
    });
  }

  cancel() {
    this.$router.back();
  }

  @Watch("form.startKilometers")
  @Watch("form.endKilometers")
  calcTotalKilometers() {
    let start = this.form.startKilometers ?? 0;
    let end = this.form.endKilometers ?? 0;
    this.form.totalKilometers = Number(Number(start + end).toFixed(1));
  }


  roleRule = {
    applyReason: [
      {
        required: true,
        message: "请填写申请事由",
        trigger: "blur"
      }
    ],
    startTime: [
      {
        required: true,
        message: "请填写出发时间",
        trigger: "blur"
      }
    ],
    startKilometers: [
      {
        required: true,
        message: "请填写行公里数",
        trigger: "blur"
      }
    ],
    startDeparture: [
      {
        required: true,
        message: "请填写出发地",
        trigger: "blur"
      }
    ],
    startDestination: [
      {
        required: true,
        message: "请填写目的地",
        trigger: "blur"
      }
    ],

    endTime: [
      {
        required: true,
        message: "请填写出发时间",
        trigger: "blur"
      }
    ],
    endKilometers: [
      {
        required: true,
        message: "请填写行公里数",
        trigger: "blur"
      }
    ],
    endDeparture: [
      {
        required: true,
        message: "请填写出发地",
        trigger: "blur"
      }
    ],
    endDestination: [
      {
        required: true,
        message: "请填写目的地",
        trigger: "blur"
      }
    ],
    fee: [
      {
        required: true,
        message: "请填补贴费用",
        trigger: "blur"
      }
    ],
  };
}


  import {Component, Prop, Ref, Vue} from "vue-property-decorator";
  import RejectDialog from "@/components/RejectDialog/index.vue";
  import api from "@/api";
  import {UserModule} from "@/store/modules/user";
  import AgileAuditTag from "@/components/AgileAuditTag/index.vue";
  import {AuditUserLogType} from "@/api/appService";

  interface IAuditDto {
    auditFlowId: string;
    auditStatus?: number;
    id: string | number;
  }

  interface IAuditHostTypeGet{
    getHostType(rowItemDto:any):AuditUserLogType;
  }

  @Component({
    name: 'AuditTabPane', components: {
      RejectDialog, AgileAuditTag
    }
  })
  export default class AuditTabPane extends Vue {

    @Ref() rejectDialog!: RejectDialog;
    @Ref() backDialog!: RejectDialog;


    @Prop({required: true})
    fetchDataFunction!: Function;

    @Prop({required:false})
    queryParams!:any;


    loading = true;

    tableItems: [] = [];


    created() {
      this.fetchData();
    }


    fetchData() {
      this.loading = true;
      const requestData = Object.assign({maxResultCount:100},this.queryParams??{});
      const promise = this.fetchDataFunction.call(this).call(this,requestData);
      promise.then((res: any) => {
        this.tableItems = (res as any).items!;
        this.loading = false;

        this.$emit('count-changed',this.tableItems.length)
      })
    }

    handleAudit(itemDto:any){
      this.$emit('audit',itemDto);
    }









  }

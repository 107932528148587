
import { Component, Vue } from "vue-property-decorator";
import { OrganizationUnitDto } from "@/api/appService";
import api from "@/api";
import moment from "moment";
@Component({
  name: "organizationUnitDetail",
  filters: {
    formatOrganizationType(organizationType?: string) {
      switch (organizationType) {
        case "Partner":
          return "合作方";

        case "School":
          return "学校";
      }
    }
  }
})
export default class OrganizationApplyDetail extends Vue {
  orgId?: number;
  detail: OrganizationUnitDto = {};
  created() {
    if (this.$route.params.id) {
      this.orgId = Number(this.$route.params.id);
      this.fetchDetail();
    }
  }
  // 获取详情
  async fetchDetail() {
    await api.organizationUnit.get({ id: this.orgId }).then(res => {
      this.detail = { ...res };
    });
  }

  // 返回
  private cancel() {
    this.$router.back();
  }
}

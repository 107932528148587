
import {Vue, Component, Watch} from 'vue-property-decorator';
import ItemBorrowing from '@/views/workbench/itemBorrowing/list.vue';
import AuditList from '@/views/workbench/itemBorrowing/auditList.vue'
import AuditMenu from '@/views/workbench/components/AuditMenu.vue'

import Cookies from "js-cookie";

@Component({
  name: "ItemBorrowingList",
  components: {
    ItemBorrowing,
    AuditList,
    AuditMenu
  }
})

export default class index extends Vue {
  activeName: string = "ItemBorrowingFirst";

  tabArray: string[] = ['ItemBorrowingFirst', 'ItemBorrowingSecond', 'ItemBorrowingThird']
  toDoCount: number = 0;

  created() {
    let tab = Cookies.get('Workbench')
    if (tab) {
      if (this.tabArray.includes(tab)) {
        this.activeName = tab;
      } else {
        Cookies.set('Workbench', '')
      }
    }
  }

  handleCountChange(target: any) {
    this.$emit('count-changed', ['ItemBorrowing', target]);
    // this.toDoCount = target;
  }

  handleClick(tab: any, event: any) {
    Cookies.set('Workbench', tab.name);
  }
}

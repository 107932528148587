
import {Component, Model, Prop, Vue} from "vue-property-decorator";
import Base64 from "@/utils/Base64";
import api from "@/api";
import {UserModule} from "@/store/modules/user";
import {AppModule} from "@/store/modules/app";
import {AttachmentHostType} from "@/api/appService";

@Component({
  name:'SimpleUploadFile'
})
export default class SimpleUploadFile extends Vue {

  @Model('change', {type: String, default: ''}) readonly url!: string;

  @Prop({required: true})
  hostType!: AttachmentHostType;

  async handleSuccess(res: any, file: any) {
    if (res.success) {
      this.$emit('change', res.result.url)
    }
  }

  get headers() {
    return {
      'Abp.OrganizationUnitId': UserModule.getOU?.id,
      'Abp.TenantId': AppModule.getTenantId,
      'Authorization': `Bearer ${UserModule.getToken}`,
      '.AspNetCore.Culture': 'c=zh-Hans|uic=zh-Hans'
    }
  }

  get uploadParameter() {
    return {hostType: this.hostType};
  }

  isImage(url:string){
    if(url.indexOf('jpg')>-1||
      url.indexOf('JPG')>-1||
      url.indexOf('jpeg')>-1||
      url.indexOf('JPEG')>-1||
      url.indexOf('gif')>-1||
      url.indexOf('GIF')>-1||
      url.indexOf('png')>-1||
      url.indexOf('PNG')>-1||
      url.indexOf('bmp')>-1||
      url.indexOf('BMP')>-1){
      return true;
    }
    else{
      return false;
    }
  }

  private beforeUpload(file: any) {
    console.log(file);
    // const isJPG = file.type === "image/jpeg";
    // const isLt2M = file.size / 1024 / 1024 < 3;
    // if (!isLt2M) {
    //   this.$message.error("上传头像图片大小不能超过 3MB!");
    //   return false;
    // } else {
    //   return true;
    // }

    return true;
  }
}

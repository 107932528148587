

import {Component, Vue} from "vue-property-decorator";
import AuditTabPane from "@/views/myAudit/components/AuditTabPane.vue";
import api from '@/api';
import {AuditUserLogType, CustomFormType} from "@/api/appService";

@Component({
  name: 'VehicleUsageApplicationAuditList',
  components: {AuditTabPane}
})
export default class VehicleUsageApplicationAuditList extends Vue {

  fetchData() {
    return api.vehicleUsageApplication.getMyAll;
  }

  get queryParams() {
    return {
      serviceRecordType: 'School'
    }
  }


  async handleAudit(dto: any) {
    this.$router.push({
      name: "vehicleUsageApplication-detail",
      params: {id: dto.id!.toString()}
    });
  }

  handleCountChange(value: number) {
    this.$emit('count-changed', value);
  }

}


import {Vue, Component, Watch} from 'vue-property-decorator';
import VehicleUsageApplication from '@/views/workbench/vehicleUsageApplication/list.vue';
import AuditList from '@/views/workbench/vehicleUsageApplication/auditList.vue'
import AuditMenu from '@/views/workbench/components/AuditMenu.vue'

import Cookies from "js-cookie";

@Component({
  name: "VehicleUsageApplicationList",
  components: {
    VehicleUsageApplication,
    AuditList,
    AuditMenu
  }
})

export default class index extends Vue {
  activeName: string = "VehicleUsageApplicationFirst";

  tabArray: string[] = ['VehicleUsageApplicationFirst', 'VehicleUsageApplicationSecond', 'VehicleUsageApplicationThird']
  toDoCount: number = 0;

  created() {
    let tab = Cookies.get('Workbench')
    if (tab) {
      if (this.tabArray.includes(tab)) {
        this.activeName = tab;
      } else {
        Cookies.set('Workbench', '')
      }
    }
  }

  handleCountChange(target: any) {
    this.$emit('count-changed', ['VehicleUsageApplication', target]);

    // this.toDoCount = target;
  }

  handleClick(tab: any, event: any) {
    Cookies.set('Workbench', tab.name);
  }
}

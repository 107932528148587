

import {Component, Vue} from "vue-property-decorator";
import AuditTabPane from "@/views/myAudit/components/AuditTabPane.vue";
import api from '@/api';
import {AuditUserLogType, CustomFormType} from "@/api/appService";

@Component({name:'MatterAuditAuditList',
  components: {AuditTabPane}
})
export default class MatterAuditAuditList extends Vue{

  fetchData(){
    return api.matterAuditForm.getMyAll;
  }

  get queryParams(){
    return {
      serviceRecordType:'School'
    }
  }


  async handleAudit(dto:any){
    this.$router.push({
      name: "matter-audit-detail",
      params: {id: dto.id!.toString()}
    });


    // return this.$router.push({
    //   name: 'matter-audit-detail',
    //   query: {
    //     id: dto.id,
    //   }
    // })
  }

  handleCountChange(value:number){
    this.$emit('count-changed',value);
  }

}


import {Component, Ref, Vue} from "vue-property-decorator";
import {
  AttachmentHostType,
  AuditFlowScope,
  InventoryOutFormDto,
} from "@/api/appService";
import api from "@/api";
import moment from "moment";
import {AuditBodyDto} from "@/utils/customDto";
import AuditButtons from "@/components/AuditButtons/index.vue";
import AttachmentsView from "@/components/AttachmentsView/index.vue";
import ExportWord from "@/components/DownloadWord/index.vue";

@Component({
  name: "InventoryOutFormDetail",
  components: {
    ExportWord,
    AttachmentsView,
    AuditButtons
  }
})
export default class InventoryOutFormDetail extends Vue {
  @Ref() auditButtons!: AuditButtons;
  dataId?: number;
  detail: InventoryOutFormDto = {};
  auditParams: AuditBodyDto = {
    hostId: undefined,
    hostType: AuditFlowScope.InventoryOut,
    auditFlowId: undefined,
    auditStatus: undefined,
    audit: undefined
  }
  //
  selector: string = "table-detail";
  filename: string = "福建省恒申慈善基金会物资出库表";
  styles: string = "";

  itemList:any[]=[];

  created() {
    this.dataId = Number(this.$route.params.id);
    if (this.dataId) {
      this.fetchDetail();
    }
  }

  // 获取详情
  async fetchDetail() {
    await api.inventoryOut.get({id: this.dataId}).then(res => {
      this.detail = {...res};
      this.itemList=JSON.parse(this.detail.detailJson??'');
      this.auditParams!.hostId = res.id + ''
      this.auditParams!.auditFlowId = res.auditFlowId;
      this.auditParams!.auditStatus = res.auditStatus;
      this.auditParams!.audit = res.audit;
      if (res.auditFlowId) {
        (this.auditButtons as any).fetchCanAudit()
      }
    });
  }

  // 返回
  private cancel() {
    this.$router.back();
  }

  fetchData() {
    return api.inventoryOut.audit;
  }

  changeAudit() {
    this.fetchDetail()
  }
}

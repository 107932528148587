
import api from "@/api"; //ABP API接口
import {Vue, Component, Ref, Watch, Prop} from "vue-property-decorator";

import {
  OrganizationUnitCreateOrUpdateDto,
  PartnerCapitalApplyDto,
  AuditFlowScope
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";
import OrganizationSelect from "@/components/OrganizationSelect/index.vue";

@Component({
  name: "RoleList",
  components: {
    PagedTableView,
    AbSelect,
    AgileAuditTag,
    OrganizationSelect
  }
})
export default class PartnerCapitalApplyList extends Vue {
  @Ref() readonly pagedTableView!: any;
  type: string = "internal";
  queryForm = {
    matter: "",
    startDate: "",
    endDate: ""
  };

  @Prop({required: false})
  createdByMe?: boolean;

  @Prop({required: false})
  approved?: boolean;

  // 获取表数据
  fetchData(params: any) {
    return api.matterAuditForm.getAll({...params, createdByMe: this.createdByMe, isApproved: this.approved});
  }

  //获取审核状态
  getAuditStatus(index: number, row: any) {
    if ((row.audit != null && row.audit >= 0) && row.auditStatus === row.audit) {//已审核
      return true;
    } else if (row.audit === -1) {//被拒绝
      return false;
    } else if (row.audit == null) {//未开始
      return false;
    } else {//审核中
      return true;
    }
  }

  // 新建
  handleCreate() {
    this.$router.push({
      name: "matter-audit-create",
    });
  }

  // 编辑
  handleEdit(index: number, row: any) {
    this.$router.push({
      name: "matter-audit-edit",
      params: {
        id: row.id
      },
    });
  }

  handleMenu(e: any) {
    switch (e.type) {
      case "detail": {
        this.jumpDetail(e.index, e.item);
        return;
      }
      case "edit": {
        this.handleEdit(e.index, e.item);
        return;
      }
      case "delete": {
        this.handleDelete(e.index, e.item);
        return;
      }
      case "startAudit": {
        this.handleStartAudit(e.index, e.item);
        return;
      }
      case "auditFlow": {
        this.handleAuditFlow(e.index, e.item);
        break;
      }
    }
  }

  // 查看详情
  jumpDetail(index: number, row: PartnerCapitalApplyDto) {
    this.$router.push({
      name: "matter-audit-detail",
      params: {id: row.id!.toString()}
    });
  }

  // 提交审核
  async handleStartAudit(
    item: number,
    node: OrganizationUnitCreateOrUpdateDto
  ) {
    await api.matterAuditForm.startAudit({
      body: {
        id: node.id
      }
    }).then(res => {
      this.$message({
        type: "success",
        message: "操作成功!"
      });
    })
  }

  // 删除
  async handleDelete(index: number, row: OrganizationUnitCreateOrUpdateDto) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.matterAuditForm.delete({id: row.id}).then(res => {
        this.$message({
          type: "success",
          message: "删除成功!"
        });
      });
    });
  }

  handleAuditFlow(index: number, row: any) {
    this.$router.push({
      name: "auditFlowDetail",
      params: {
        hostType: AuditFlowScope.MatterAudit,
        hostId: row.id,
        auditFlowId: row.auditFlowId
      }
    });
  }
}

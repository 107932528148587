
  import {Component, Prop, Vue} from "vue-property-decorator";

    @Component({name:'AuditCount'})
    export default class AuditCount extends Vue{

      @Prop({required:true})
      value!:number;

    }


import {Component, Ref, Vue} from "vue-property-decorator";
import {
  AttachmentHostType,
  AuditFlowScope,
  ItemBorrowingFormDto,
} from "@/api/appService";
import api from "@/api";
import moment from "moment";
import {AuditBodyDto} from "@/utils/customDto";
import AuditButtons from "@/components/AuditButtons/index.vue";
import AttachmentsView from "@/components/AttachmentsView/index.vue";
import ExportWord from "@/components/DownloadWord/index.vue";

@Component({
  name: "ItemBorrowingFormDetail",
  components: {
    ExportWord,
    AttachmentsView,
    AuditButtons
  }
})
export default class ItemBorrowingFormDetail extends Vue {
  @Ref() auditButtons!: AuditButtons;
  dataId?: number;
  detail: ItemBorrowingFormDto = {};
  auditParams: AuditBodyDto = {
    hostId: undefined,
    hostType: AuditFlowScope.ItemBorrowing,
    auditFlowId: undefined,
    auditStatus: undefined,
    audit: undefined
  }
  //
  selector: string = "table-detail";
  filename: string = "福建省恒申慈善基金会物品借用单";
  styles: string = "";

  itemList: any[] = [];
  isCanAudit: boolean = false;

  created() {
    this.dataId = Number(this.$route.params.id);
    if (this.dataId) {
      this.fetchDetail();
    }
  }

  // 获取详情
  async fetchDetail() {
    await api.itemBorrowing.get({id: this.dataId}).then(res => {
      this.detail = {...res};
      this.itemList = JSON.parse(this.detail.itemsJson ?? '');
      this.auditParams!.hostId = res.id + ''
      this.auditParams!.auditFlowId = res.auditFlowId;
      this.auditParams!.auditStatus = res.auditStatus;
      this.auditParams!.audit = res.audit;
      if (res.auditFlowId) {
        (this.auditButtons as any).fetchCanAudit()
      }
      //
      const canAuditParams = {
        auditFlowId: this.auditParams.auditFlowId,
        auditStatus: this.auditParams.auditStatus,
        audit: this.auditParams.audit
      }
      api.auditFlow.canAudit({body: canAuditParams}).then(res => {
        this.isCanAudit = res;
      })


    });
  }

  handleEdit() {
    this.$router.push({
      name: "itemBorrowing-edit",
      params: {
        id: this.dataId!.toString()
      },
      query: {
        isReturnEdit: '1'
      }
    });
  }

  // 返回
  private cancel() {
    this.$router.back();
  }

  fetchData() {
    return api.itemBorrowing.audit;
  }

  changeAudit() {
    this.fetchDetail()
  }
}

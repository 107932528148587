
import {
  Component,
  Vue,
  Inject,
  Prop,
  Watch,
  Ref
} from "vue-property-decorator";

import api from "@/api";
import {
  CapitalIncomeRecordFromOtherDto,
  CapitalIncomeRecordFromOtherCreateOrUpdateDto,
  CapitalIncomeRecordFromOtherDetailCreateOrUpdateDto,
  CapitalProperty,
  IncomeCategory,
  CurrencyType,
  DataDictionaryDto,
  PartnerCapitalApplySettlementDetailCreateOrUpdateDto,
  PartnerCapitalApplyDetailCreateOrUpdateDto, AttachmentHostType
} from "@/api/appService";
import {ElForm} from "element-ui/types/form";
import validate from "@/utils/validate";
import {getCapitalPropertyList, getCurrencyType, getIncomeCategory, getPayTypes} from "@/utils/enumToSelectItems";
import SimpleUploadFile from "@/components/SimpleUploadFile/index.vue";
import MultipleUploadFile from "@/components/MultipleUploadFile/index.vue";

@Component({
  name: "EditPartnerCapitalApplySettlementDetail",
  components: {MultipleUploadFile, SimpleUploadFile},
  filters: {}
})
export default class EditPartnerCapitalApplySettlementDetail extends Vue {
  @Ref() readonly dataForm!: ElForm;

  @Prop({required: true})
  dataId!: string;

  otherDetailListDefaultData: CapitalIncomeRecordFromOtherDetailCreateOrUpdateDto[] = [];
  defaultData: PartnerCapitalApplySettlementDetailCreateOrUpdateDto = {
    purpose: undefined,
    purposeArray: [],
    applyPrice: undefined,
    settlementPrice: undefined,
    remark: undefined,
    otherFund: undefined,
    otherFundSource: undefined,
    invoiceFile: undefined,
    id:undefined
  };

  show = false;
  form: PartnerCapitalApplySettlementDetailCreateOrUpdateDto = {...this.defaultData};
  editIndex?:number;

  applyList: PartnerCapitalApplyDetailCreateOrUpdateDto[] = [];

  payType = getPayTypes();

  get title() {
    return '资金申请结算';
  }


  @Watch("show")
  async onShowChange(value: boolean) {
    if (value) {
      const loading = this.$loading({
        target: ".el-dialog"
      });

      if (this.dataId||this.editIndex!=undefined) {
        setTimeout(() => {
          loading.close();
        }, 200);
      } else {
        this.form = {...this.defaultData};
        setTimeout(() => {
          loading.close();
        }, 200);
      }
    } else {
      this.form = {...this.defaultData};
    }
    this.$nextTick(() => {
      this.dataForm.clearValidate();
    });
  }

  created() {
    this.initDefaultData();
  }

  initDefaultData() {

  }

  calcApplyPrice() {
    let total = 0;
    let _this = this;
    if (this.form.purposeArray && this.form.purposeArray.length > 0) {
      this.form.purposeArray.forEach(s => {
        let target = _this.applyList.find(d => d.id == s);
        if (target) {
          total += target.budgetQuotation ?? 0;
        }
      })
    }
    this.form.applyPrice = total;
  }

  get hostTypeFile() {
    return AttachmentHostType.PartnerCapitalApplySettlementDetail;
  }

  async save() {
    console.log(this.form);
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        this.show = false;
        this.$emit("onSave",{form:this.form,index:this.editIndex});
      }
    });
  }

  cancel() {
    this.show = false;
  }

  linkRule = {
    purposeArray: [
      {
        required: true,
        message: "请选择资金用途",
        trigger: "blur"
      }
    ],
    applyPrice: [
      {
        required: true,
        message: "请输入申请预算资金（元）",
        trigger: "blur"
      }
      /*{
                    type: "number",
                    message: "账号必须为数字",
                    trigger: "blur"
                }*/
    ],
    settlementPrice: [
      {
        required: true,
        message: "请输入总支出（元）",
        trigger: "blur"
      }
    ],
    otherFund: [
      {
        required: true,
        message: "请输入实际配比资金（元）",
        trigger: "blur"
      }
    ],
    otherFundSource: [
      {
        required: true,
        message: "请输入配比资金来源",
        trigger: "blur"
      }
    ],
    savedAttachments: [
      {
        required: true,
        message: "请上传票据",
        trigger: "blur"
      }
    ],
    displayOrder: [
      {
        required: true,
        message: "请输入显示顺序",
        trigger: "change"
      },
      {
        type: "number",
        message: "显示顺序必须为数字",
        trigger: "change"
      }
    ]
  };

  detailRule = {
    money: [
      {
        required: true,
        trigger: "change",
        validator: validate.validMoney
      }
    ]
  };
}


import {Vue, Component, Watch} from 'vue-property-decorator';
import MatterAudit from '@/views/workbench/matterAudit/home.vue';
import InventoryIn from '@/views/workbench/inventoryIn/home.vue';
import InventoryOut from '@/views/workbench/inventoryOut/home.vue';
import PrivateCarPublicSubsidy from '@/views/workbench/privateCarPublicSubsidy/home.vue';
import TravelApproval from '@/views/workbench/travelApproval/home.vue';
import VehicleUsageApplication from '@/views/workbench/vehicleUsageApplication/home.vue';



import ItemBorrowing from '@/views/workbench/itemBorrowing/home.vue';

import AuditMenu from '@/views/workbench/components/AuditMenu.vue'
import Cookies from "js-cookie";
import AuditCount from "@/views/myAudit/components/AuditCount.vue";

@Component({
  name: "WorkbenchList",
  components: {
    AuditCount,
    MatterAudit,
    InventoryIn,
    InventoryOut,
    ItemBorrowing,
    PrivateCarPublicSubsidy,
    TravelApproval,
    VehicleUsageApplication,
    AuditMenu
  }
})

export default class index extends Vue {

  activeName: string = "MatterAudit";
  created() {
    let tab = Cookies.get('WorkbenchHome')
    if (tab) {
      this.activeName = tab;
    }
  }


  handleClick(tab: any, event: any) {
    Cookies.set('WorkbenchHome', tab.name);
  }

  countDic: any = {
    'MatterAudit': 0,
    'InventoryIn': 0,
    'InventoryOut':0,
    'ItemBorrowing':0,
    'PrivateCarPublicSubsidy':0,
    'TravelApproval':0,
    'VehicleUsageApplication':0,
  }

  handleCountChange(target: any) {
    this.countDic[target[0]] = target[1];
  }
}
